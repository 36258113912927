import React from 'react';
import './App.css';
import { appendScript } from './utils/appendScript'

// function App() {
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      youtubeUrl: ''
    };
  }

  handleClick = async () => {
    window.location.href = 'https://yt-dowloader.glitch.me/youtube/' + this.state.youtubeUrl;
  }

  handleChange = async (e) => {
    console.log('e.target.value', e.target.value);
    let x = e.target.value.split("/")[e.target.value.split("/").length -1];
    console.log('x',x);
    this.setState({
      [e.target.name]: x
    });
  }

  componentDidMount () {
    appendScript("/ads.js");
  }

  render() {
    return (
      <div>
        <div className="header-div">
          YT DOWNLOAD
        </div>
        <div className="yt-form">
          <div className="input-div">
            <input onChange={(e) => this.handleChange(e)} name="youtubeUrl" type="text" value={this.state.youtubeUrl} placeholder="Enter Youtube URL!" />
          </div>
          <div className="button-div">
            <button onClick={this.handleClick}>Download</button>
          </div>
        </div>
      </div>
    )
  };
}

export default App;
